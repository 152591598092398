<template>
  <div>
    <b-row>
      <b-col sm="8" md="8">
        <b-card style="height: 7rem; overflow: auto">
          <b-card-text class="">
            <b-row align-h="between">
              <b-col class="d-flex align-items-center justify-content-start">
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      size="4rem"
                      rounded="lg"
                      :variant="'light-success'"
                    >
                      <b-icon scale="2rem" icon="telephone-plus"></b-icon>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ statistics.inbound_success }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ lang("t_answered") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col>
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      size="4rem"
                      rounded="lg"
                      :variant="'light-success'"
                    >
                      <b-icon scale="2rem" icon="person-check"></b-icon>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ statistics.outbound_success }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0 text-truncate">
                      {{ lang("t_successfullCall") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col>
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      size="4rem"
                      rounded="lg"
                      :variant="'light-danger'"
                    >
                      <b-icon scale="2rem" icon="telephone-minus"></b-icon>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ statistics.inbound_failed }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ lang("t_abandoned") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col>
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      size="4rem"
                      rounded="lg"
                      :variant="'light-danger'"
                    >
                      <b-icon scale="2rem" icon="person-x"></b-icon>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ statistics.outbound_failed }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ lang("t_unsuccessfullCall") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-text>
          <!-- <b-card-text class="mt-2">
            <b-row>
              <b-col>
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      size="3rem"
                      rounded="lg"
                      :variant="'light-danger'"
                    >
                      <b-icon scale="2rem" icon="telephone-minus"></b-icon>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ statistics.inbound_failed }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ lang("t_abandoned") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col>
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      size="3rem"
                      rounded="lg"
                      :variant="'light-danger'"
                    >
                      <b-icon scale="2rem" icon="person-x"></b-icon>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ statistics.outbound_failed }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ lang("t_unsuccessfullCall") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-text> -->
        </b-card>
      </b-col>
      <b-col sm="4" md="4">
        <b-card style="height: 7rem; overflow: auto">
          <div>
            <b-row>
              <b-col cols="12">
                <b-row class="mb-1">
                  <b-col>
                    <b-media no-body>
                      <b-media-aside>
                        <b-avatar
                          size="4rem"
                          rounded="lg"
                          :variant="'light-info'"
                        >
                          <!-- <b-icon scale="2rem" icon="cup-hot"></b-icon> -->
                          <feather-icon
                            size="24"
                            icon="CoffeeIcon"
                          ></feather-icon>
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ statistics.break_time }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ lang("t_usedBreakTime") }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col>
                    <b-media no-body>
                      <b-media-aside>
                        <b-avatar
                          size="4rem"
                          rounded="lg"
                          :variant="'light-warning'"
                        >
                          <!-- <b-icon scale="2rem" icon="person-check"></b-icon> -->
                          <feather-icon
                            size="24"
                            icon="ClockIcon"
                          ></feather-icon>
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ statistics.remaining_break_time }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          {{ lang("t_remaining") }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col> </b-col>
                </b-row>
              </b-col>
              <!-- <b-col :cols="dynamicCols">
                <chartjs-component-doughnut-chart
                  :key="s_chart_id"
                  :height="100"
                  :data="doughnutChart.data"
                  :options="doughnutChart.options"
                />
              </b-col> -->
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col v-if="false" sm="6" md="4">
        <b-card
          no-body
          style="height: 12rem; overflow: auto"
          :title="lang('t_productivity')"
        >
          <b-card-body>
            <b-row>
              <b-col>
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      size="3rem"
                      rounded="lg"
                      :variant="'light-primary'"
                    >
                      <i
                        style="font-size: 24px"
                        class="bi bi-rocket-takeoff"
                      ></i>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ "%" + statistics.productivity }}
                      <b-icon
                        v-if="
                          statistics.productivity > statistics.avg_productivity
                        "
                        variant="success"
                        icon="arrow-up-short"
                      ></b-icon>
                      <b-icon
                        v-else
                        variant="danger"
                        icon="arrow-down-short"
                      ></b-icon>
                      <b-badge class="float-right" variant="light-secondary">
                        {{ `%${statistics.avg_productivity}` }}
                      </b-badge>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ lang("t_productivity") }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-text class="mx-1">
            <chartjs-component-bar-chart
              :key="p_chart_id"
              ref="productivity"
              :height="100"
              :data="latestBarChart.data"
              :options="latestBarChart.options"
            />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="4">
        <b-card
          class="card-transaction"
          style="height: 24rem; overflow: auto"
          no-body
        >
          <b-card-header>
            <b-card-title>{{ selected_type.display_name }}</b-card-title>

            <b-dropdown
              variant="link"
              no-caret
              toggle-class="py-50 px-50"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item
                @click="selected_type = item"
                v-for="item in types.filter(
                  (e) => e.internal_name != selected_type.internal_name
                )"
                :key="item.internal_name"
                href="#"
              >
                {{ item.display_name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-card-body v-if="selected_type.internal_name == 'statuses'">
            <div
              v-for="(item, i) in st_data"
              class="transaction-item mb-50"
              :key="i"
            >
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    :variant="
                      reason_colors.hasOwnProperty(item.display_name)
                        ? `light-${reason_colors[item.display_name]}`
                        : 'light-primary'
                    "
                  >
                    <feather-icon
                      v-if="
                        getReasonIcon(item.display_name).includes('feather')
                      "
                      size="18"
                      :icon="getReasonIcon(item.display_name).split('_'[1])"
                    />
                    <i
                      v-if="getReasonIcon(item.display_name).includes('bi-')"
                      class="bi font-medium-3"
                      :class="getReasonIcon(item.display_name)"
                    ></i>
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <span class="d-block font-small-4">
                    {{ item.display_name }}
                  </span>
                  <b-badge variant="light-secondary"></b-badge>
                  <small class="text-muted">{{ item.category }}</small>
                </b-media-body>
              </b-media>
              <div
                class="font-weight-bolder font-medium-1"
                :class="true ? 'text-secondary' : 'text-secondary'"
              >
                {{ toHHMMSS(item.time) }}
              </div>
            </div>
          </b-card-body>
          <b-card-body v-else>
            <div
              v-for="(item, i) in Object.keys(performance_info)"
              class="transaction-item mb-50"
              :key="i"
            >
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    :variant="performance_info[item].variant"
                  >
                    <!-- <feather-icon size="18" :icon="'UserIcon'" /> -->
                    <i
                      class="bi font-medium-3"
                      :class="performance_info[item].icon"
                    ></i>
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <span class="d-block font-small-4">
                    {{ lang(`t_${item}`) }}
                  </span>
                  <b-badge variant="light-secondary"></b-badge>
                  <small class="text-muted">{{
                    lang(`t_${item}Descrition`)
                  }}</small>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder text-secondary font-medium-1">
                {{ performance_info[item].value }}
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="12" md="4">
        <b-card
          style="height: 24rem; overflow-y: auto"
          class="card-tiny-line-stats"
          body-class="pb-50"
        >
          <b-row>
            <b-col>
              <b-media no-body>
                <b-media-aside>
                  <b-avatar size="3rem" rounded="lg" :variant="'light-primary'">
                    <i style="font-size: 24px" class="bi bi-rocket-takeoff"></i>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ "%" + statistics.productivity }}
                    <b-icon
                      v-if="
                        statistics.productivity > statistics.avg_productivity
                      "
                      variant="success"
                      icon="arrow-up-short"
                    ></b-icon>
                    <b-icon
                      v-else
                      variant="danger"
                      icon="arrow-down-short"
                    ></b-icon>
                    <b-badge class="float-right" variant="light-secondary">
                      {{ `%${statistics.avg_productivity}` }}
                    </b-badge>
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ lang("t_productivity") }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
          <!-- chart -->
          <vue-apex-charts
            class="mt-1"
            height="70"
            :options="statisticsProfit.chartOptions"
            :series="chart_series"
            :key="p_chart_id"
          />

          <div
            v-if="!other_permission.includes('display_queue_abandon_details')"
          >
            <div
              v-if="
                waiting_on_queue_data.filter((e) => e.call_count > 0).length
              "
              class="border-top mt-2"
            >
              <div class="mb-2"></div>
              <b-row
                class="mt-1"
                v-for="(item, i) in waiting_on_queue_data
                  .filter((e) => e.call_count > 0)
                  .slice(0, 3)"
                :key="i"
              >
                <b-col class="py-0">
                  <b-badge variant="light-primary">
                    {{
                      queues.find((e) => e.internal_name == item.queue)
                        ? queues.find((e) => e.internal_name == item.queue)
                            .display_name
                        : item.queue
                    }}
                  </b-badge>
                  <h4 class="float-right py-0 mb-0">
                    {{ item.call_count }}
                    <b-icon variant="success" icon="arrow-up-short"></b-icon>
                  </h4>
                </b-col>
              </b-row>
            </div>
            <div
              style="height: 12rem"
              v-else
              class="border-top text-center d-flex justify-content-center align-items-center"
            >
              <span class="text-muted my-auto">
                {{ lang("t_noRecordOnQueue") }}
              </span>
            </div>
          </div>
          <div v-else>
            <div
              v-if="
                waiting_on_queue_data.filter((e) => e.call_count >= 0).length
              "
              class="border-top mt-2"
            >
              <div class="mb-2"></div>
              <b-row
                class="mt-1"
                v-for="(item, i) in waiting_on_queue_data.filter(
                  (e) => e.call_count >= 0
                )"
                :key="i"
              >
                <b-col class="py-0">
                  <b-badge
                    class="cursor-pointer"
                    @click="showAbandonList(item.queue)"
                    variant="light-primary"
                  >
                    {{
                      queues.find((e) => e.internal_name == item.queue)
                        ? queues.find((e) => e.internal_name == item.queue)
                            .display_name
                        : item.queue
                    }}
                  </b-badge>
                  <h4 class="float-right py-0 mb-0">
                    {{ item.call_count }}
                    <b-icon
                      v-if="item.call_count > 0"
                      variant="success"
                      icon="arrow-up-short"
                    ></b-icon>
                  </h4>
                </b-col>

                <!-- <b-col cols="3">
                <b-avatar
                  rounded="sm"
                  :variant="set_waiting_in_queue_color(item.call_count)"
                >
                  {{ item.call_count }}
                </b-avatar>
              </b-col>
              <b-col cols="9"> </b-col> -->
              </b-row>
            </div>
          </div>
        </b-card>

        <!-- <b-card style="height: 9.5rem; overflow-y: auto">
          <b-row>
            <b-col v-for="(item, i) in waiting_on_queue_data" :key="i">
              <b-card no-body class="text-center">
                <b-badge variant="light-primary">
                  {{
                    queues.find((e) => e.internal_name == item.queue)
                      ? queues.find((e) => e.internal_name == item.queue)
                          .display_name
                      : item.queue
                  }}
                </b-badge>
                <h4>
                  {{ item.call_count }}
                </h4>
              </b-card>
            </b-col>
          </b-row>
        </b-card> -->
      </b-col>
      <b-col sm="12" md="4">
        <voyce-table-without-card
          :card_height="'24rem'"
          :title="'t_finishCodes'"
          :sub_title="'Yapılan tüm çağrılar'"
          :data="fc_data"
          :fields="fc_fields"
          :sortDesc="true"
          :extra="{
            total_finish_code_counts,
          }"
        ></voyce-table-without-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="12">
        <voyce-table-without-card
          :card_height="'22.1rem'"
          :title="'t_callHistory'"
          :sub_title="'Yapılan tüm çağrı görüşmeleri'"
          :data="ch_data"
          :fields="ch_fields"
          :sortDesc="true"
        ></voyce-table-without-card>
      </b-col>
      <b-col v-if="false" sm="12" md="4">
        <b-card no-body style="height: 24rem; overflow: auto">
          <!-- <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_queue") }}
            </b-card-title>
            <b-card-sub-title class="mt-1">
              {{ lang("t_waitingInQueue") }}
            </b-card-sub-title>
          </b-card-header> -->
          <b-card-body>
            <b-row
              class="mb-1"
              v-for="(item, i) in waiting_on_queue_data"
              :key="i"
            >
              <b-col class="py-0">
                <b-badge variant="light-primary">
                  {{
                    queues.find((e) => e.internal_name == item.queue)
                      ? queues.find((e) => e.internal_name == item.queue)
                          .display_name
                      : item.queue
                  }}
                </b-badge>
                <h4 class="float-right py-0 mb-0">
                  {{ item.call_count }}
                  <b-icon variant="success" icon="arrow-up-short"></b-icon>
                </h4>
              </b-col>

              <!-- <b-col cols="3">
                <b-avatar
                  rounded="sm"
                  :variant="set_waiting_in_queue_color(item.call_count)"
                >
                  {{ item.call_count }}
                </b-avatar>
              </b-col>
              <b-col cols="9"> </b-col> -->
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      static
      dialog-class="dClass"
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_mrAbandonedCalls')"
      hide-footer
      centered
      v-model="modal_abandoned_details"
    >
      <b-table
        :busy="isBusy"
        :items="abandoned_details_data"
        :fields="abandoned_details_fields"
        striped
        responsive="sm"
        style="max-height: 60vh; overflow-y: auto"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(phone)="data">
          <a href="#" @click="start_call(data.value)">
            {{ GetRemoteNumber(data.value) }}</a
          >
        </template>
        <template #cell(is_reached)="data">
          <feather-icon
            size="20"
            :icon="data.value ? 'CheckCircleIcon' : 'XCircleIcon'"
          />
        </template>
        <template #cell(queue)="data">
          <b-badge variant="light-primary">
            {{
              queues.find((e) => e.internal_name == data.value)
                ? queues.find((e) => e.internal_name == data.value).display_name
                : data.value
            }}
          </b-badge>
        </template>
        <template #cell(time)="data">
          {{ timer(data.value) }}
        </template>
      </b-table>
      <b-button
        @click="abandon_call_details"
        class="float-right mr-1"
        variant="primary"
        >{{ lang("t_showCalledBack") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BContainer,
  BListGroup,
  BListGroupItem,
  BCardSubTitle,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BProgressBar,
  BProgress,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BCardFooter,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BIcon,
  BSpinner,


} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import "animate.css";
import ChartjsComponentBarChart from "./ChartjsComponentBarChart.vue";
import ChartjsComponentDoughnutChart from './ChartjsComponentDoughnutChart.vue'

import VoyceTable from "../CustomComponents/VoyceTable.vue"
import VoyceTableWithoutCard from "../CustomComponents/VoyceTableWithoutCard.vue"
const $trackBgColor = '#EBEBEB'

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
function toHHMMSS(sec_num) {
  try {
    if (isNaN(sec_num)) {
      return "00:00:00";
    }
    return new Date(sec_num * 1000).toISOString().substr(11, 8);
  } catch (error) {
    return sec_num;
  }
}


function safeDivision(denominator) {
  return denominator === 0 ? 1 : denominator;
}

const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";
export default {
  components: {
    VoyceTableWithoutCard,
    ChartjsComponentDoughnutChart,
    VoyceTable,
    BIcon,
    BContainer,
    BProgressBar,
    BCardFooter,
    BListGroup,
    BListGroupItem,
    ChartjsComponentBarChart,
    BButton,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    StatisticCardHorizontal,
    StatisticCardVertical,
    BCardCode,
    VueApexCharts,
    BTable,
    BProgress,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      p_chart_id: '0',
      s_chart_id: '1',
      sortDesc: true,
      isBusy: false,
      selectedButton: "status",
      other_permission: [],
      abandoned_details_data: [],
      modal_abandoned_details: false,
      waiting_calls: new Map(),
      total_finish_code_counts: 0,
      wc_arr: [],
      queues: [],
      total_waiting_in_queue: 0,
      queue_waiting_count_interval: "",
      selected_type: { internal_name: 'performance', display_name: globalThis._lang('t_performance') },
      types: [
        { internal_name: 'statuses', display_name: globalThis._lang('t_statuses') },
        { internal_name: 'performance', display_name: globalThis._lang('t_performance') }
      ],
      performance_info: {
        totalCallTime: { icon: 'bi-patch-plus', variant: 'light-success', value: 0 },
        avgCallTime: { icon: 'bi-graph-up', variant: 'light-primary', value: 0 },
        avgAnswerTime: { icon: 'bi-speedometer', variant: 'light-danger', value: 0 },
        avgHoldTime: { icon: 'bi-pause-circle', variant: 'light-warning', value: 0 },
        avgAcwTime: { icon: 'bi-clipboard-pulse', variant: 'light-info', value: 0 },
      },
      abandoned_details_fields: [
        { label: globalThis._lang("t_date"), key: "date", sortable: true },
        { label: globalThis._lang("t_number"), key: "phone", sortable: true },
        { label: globalThis._lang("t_queue"), key: "queue", sortable: true },
        { label: globalThis._lang("t_waitTime"), key: "time", sortable: true },
        // { label: "Arandı Mı?", key: 'is_reached',sortable:true },
      ],
      reason_colors: {
        "Available": "success",
        "Lunch": "warning",
        "Break": "warning",
        "Second Line": "info",
        "In Call - Outbound": "info",
        "In Call - Inbound": "info",
        "In Call - Dialer": "info",
        "In Call - Transfer": "info",
        "In Call - Internal": "info",
        "In Text": "info",
        "Awaiting Transfer": "info",
        "ACW": "secondary",
        "Calling": "info",
        "Ringing": "info",
        "Offline": "danger",
      },
      reason_icons: {
        "Available": "bi-wifi",
        "Break": "bi-cup-hot",
        "Second Line": "bi-2-square",
        "In Call - Outbound": "bi-telephone-outbound",
        "In Call - Inbound": "bi-telephone-inbound",
        "In Call - Dialer": "bi-telephone-inbound",
        "In Call - Transfer": "bi-telephone-forward",
        "In Call - Internal": "bi-phone-flip",
        "In Text": "feather_MessageCircleIcon",
        "Awaiting Transfer": "bi-hourglass-split",
        "ACW": "bi-window-stack",
        "Calling": "bi-telephone",
        "Ringing": "bi-phone-vibrate",
        "Offline": "bi-wifi-off",
      },
      latestBarChart: {
        data: {
          labels: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
          datasets: [
            {
              data: [0, 0, 0, 0, 0, 0, 0],
              backgroundColor: '#9e95f5',
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: true,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                  color: chartColors.blueColor,
                  zeroLineColor: chartColors.blueColor,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: false,
                gridLines: {
                  color: chartColors.blueColor,
                  zeroLineColor: chartColors.blueColor,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 100,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
      statisticsProfit: {
        chartOptions: {
          chart: {

            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -25,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.primary],
          markers: {
            size: 2,
            colors: $themeColors.primary,
            strokeColors: $themeColors.primary,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.primary,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
      doughnutChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ''
                const value = data.datasets[0].data[tooltipItem.index]
                const output = label + ": " + toHHMMSS(value)
                return output
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [
            {
              labels: ['1', '2', '3', '4', '5', '6'],
              data: [10, 10, 80,],
              backgroundColor: [chartColors.successColorShade, chartColors.warningLightColor, $themeColors.primary, $themeColors.info, $themeColors.danger, $themeColors.dark],
              borderWidth: 0,
              pointStyle: 'rectRounded',
            },
          ],
        },
      },
      chartjsData: {
        data: {
          //labels: ['Uygun', 'Mola', 'Çağrıda (Giden)', 'Çağrıda (Gelen)', 'Çalıyor', 'Çağrı Sonrası'],
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: "#7367F0",
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: "rgba(0, 0, 0, 0.25)",
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label: function (tooltipItem, data) {
                return toHHMMSS(tooltipItem.yLabel);
              },
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: "rgba(200, 200, 200, 0.2)",
                  zeroLineColor: "rgba(200, 200, 200, 0.2)",
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: "#6e6b7b",
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: "rgba(200, 200, 200, 0.2)",
                  zeroLineColor: "rgba(200, 200, 200, 0.2)",
                },
                // tickAmount: 2,

                ticks: {
                  stepSize: 1800,
                  min: 0,
                  fontColor: "#6e6b7b",
                  callback: function (value, index, ticks) {
                    return toHHMMSS(value);
                  },
                },
              },
            ],
          },
        },
      },
      statistics: {
        inbound_success: 0,
        inbound_failed: 0,
        inbound_total: 0,
        inbound_avg_time: "00:00:00",
        inbound_total_time: "00:00:00",
        outbound_success: 0,
        outbound_failed: 0,
        outbound_total: 0,
        outbound_avg_time: "00:00:00",
        outbound_total_time: "00:00:00",
        productivity: 0,
        actions: [],
        status: [],
        break_time: 0,
        remaining_break_time: 0,
        breaks: [
          { reason: "Break", count: 0 },
          { reason: "Lunch", count: 0 },
        ],
        total_finish_code: "0",
        total_break_time: 0,
      },
      monthly_chart_info: {},
      collection_statistics: {},
      statisticsItems: [
        {
          icon: "ThumbsUpIcon",
          color: "light-success",
          title: "230",
          subtitle: globalThis._lang("t_answered"),
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "CheckIcon",
          color: "light-success",
          title: "827",
          subtitle: globalThis._lang("t_reachedCalls"),
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "ThumbsDownIcon",
          color: "light-danger",
          title: "13",
          subtitle: globalThis._lang("t_unreachedCalls"),
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "XIcon",
          color: "light-danger",
          title: "121",
          subtitle: globalThis._lang("t_missedCalls"),
          customClass: "",
        },
      ],

      status_fields: [
        { key: "statu", label: globalThis._lang("t_status"), sortable: true },
        { key: "time", label: globalThis._lang("t_duration"), sortable: true },
      ],
      wq_fields: [
        { key: "queue", label: globalThis._lang("t_queue"), sortable: true },
        {
          key: "call_count",
          label: globalThis._lang("t_count"),
          sortable: true,
        },
      ],
      fc_fields: [
        {
          key: "finish_code",
          label: globalThis._lang("t_finishCode"),
          sortable: true,
        },
        { key: "count", label: globalThis._lang("t_count"), sortable: true },
        {
          key: "rate",
          label: globalThis._lang("t_rate").replace(/ /g, "\u00a0"),
          sortable: true,
        },
        {
          key: "category",
          label: globalThis._lang("t_category"),
          sortable: true,
        },
      ],
      st_data: [],
      st_fields: [
        {
          key: "display_name",
          label: globalThis._lang("t_status"),
          sortable: true,
        },
        { key: "time", label: globalThis._lang("t_time"), sortable: true },

        {
          key: "category",
          label: globalThis._lang("t_category"),
          sortable: true,
        },
      ],
      ch_fields: [
        {
          key: "call_direction",
          label: globalThis._lang("t_direction"),
          sortable: true,
        },
        {
          key: "insert_date",
          label: globalThis._lang("t_hour"),
          sortable: true,
        },
        {
          key: "contact_number",
          label: globalThis._lang("t_number"),
          sortable: true,
        },
        {
          key: "finish_code",
          label: globalThis._lang("t_finishCode"),
          sortable: true,
        },
        {
          key: "queuename",
          label: globalThis._lang("t_queue"),
          sortable: true,
        },
        {
          key: "ConnectedLength",
          label: globalThis._lang("t_duration"),
          sortable: true,
        },
        {
          key: "ACWLength",
          label: globalThis._lang("t_acw"),
          sortable: true,
        },
        {
          key: "note",
          label: globalThis._lang("t_note"),

          // label: globalThis._lang("t_actions"),
        },

        // { key: "note", label: globalThis._lang("t_note"), sortable: false },
        // {
        //   key: "RecordingFile",
        //   label: globalThis._lang("t_recordingFile"),
        //   sortable: false,
        // },
      ],
      fc_category_colors: {
        Başarılı: "success",
        Başarısız: "warning",
        Randevu: "primary",
        Other: "secondary",
      },
      fc_data: [

      ],
      ch_data: [

      ],
      waiting_on_queue_data: [

      ],
      revenueComparisonLine: {
        series: [
          {
            name: globalThis._lang("t_completed"),
            data: [45000, 47000, 44800, 47500, 45500, 48000, 46500, 48600],
          },
          {
            name: globalThis._lang("t_goal"),
            data: [46000, 48000, 45500, 46600, 44500, 46500, 45000, 47000],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ["#d0ccff", $themeColors.secondary],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              inverseColors: false,
              gradientToColors: [$themeColors.primary, "#ebe9f1"],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "1rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ["01", "05", "09", "13", "17", "21", "26", "31"],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "1rem",
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
              },
            },
          },
          grid: {
            borderColor: "#e7eef7",
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      revenue: {},
      interaction_queues: {},
      queues: [],
      custom_key: 0,
      selected_audio: "",
      chart_series: [],
    };
  },
  methods: {

    timer(seconds) {
      let _m = ("" + (Math.floor(seconds / 60) % 60)).padStart(2, "0");
      let _h = ("" + Math.floor(seconds / 3600)).padStart(2, "0");
      let _s = ("" + Math.floor(seconds % 60)).padStart(2, "0");
      return `${_h}:${_m}:${_s}`;
    },
    async abandon_call_details() {
      this.isBusy = true;
      this.abandoned_details_fields = [
        { label: globalThis._lang("t_date"), key: "date", sortable: true },
        { label: globalThis._lang("t_number"), key: "phone", sortable: true },
        { label: globalThis._lang("t_queue"), key: "queue", sortable: true },
        { label: globalThis._lang("t_waitTime"), key: "time", sortable: true },
        { label: "Geri Arama", key: "is_reached", sortable: true },
        { label: "Görüşen", key: "call_back_agent", sortable: true },
      ];
      var abandoned_list = (
        await this.$http_in.post(`agent/v1/AbandonCallDetails`, {
          phone_list: this.abandoned_details_data.map((e) => e.phone),
        })
      ).data;
      for (const item of this.abandoned_details_data) {
        console.log("item", item);
        let tmp = abandoned_list.filter(
          (e) =>
            e.StartTime > item.timestamp && e.BaseRemoteNumber == item.phone
        );
        console.log("tmp", tmp);

        item._rowVariant = tmp.length > 0 ? "success" : "danger";
        item.is_reached = tmp.length > 0 ? true : false;
        item.call_back_agent = tmp.length > 0 ? tmp[0].Agent : '';
      }
      this.abandoned_details_data = [...this.abandoned_details_data];
      this.isBusy = false;
    },

    async showAbandonList(_queue) {

      var queue = (
        await this.$http_in.get(`qmgr/v1/LiveQueue/${_queue}`)
      ).data;

      this.abandoned_details_data = [];
      this.modal_abandoned_details = true;

      for (const item of queue.AbandonedInteractionsList.filter(
        (e) => e.QueueWaitTime != null
      )) {
        this.abandoned_details_data.push({
          queue: item.Details.Queue,
          timestamp: new Date(item.AbandonTime).getTime(),
          date: new Date(item.AbandonTime + 10800000)
            .toISOString()
            .replace("T", " ")
            .replace("Z", ""),
          phone: item.Details.RemoteNumber.substr(-10),
          time: item.QueueWaitTime || 0,
        });
      }

    },
    getReasonIcon(reason) {
      if (this.reason_icons.hasOwnProperty(reason)) {
        return this.reason_icons[reason];
      }

      return 'bi-clock-history';
    },
    async getProductivtyIndex() {

      var response = (
        await this.$http_in.get(
          `agent/v1/Productivity/${globalThis.user.username}`
        )
      ).data;
      console.log("getProductivtyIndex", response);
      // response[0].Productivity = this.statistics.productivity ?? 0;
      let productivityValues = response.map(e => e.Productivity);
      let sum = productivityValues.reduce((acc, val) => acc + val, 0);
      this.statistics.avg_productivity = Math.round(sum / productivityValues.length);
      const newData = {
        labels: response.map(e => {
          const date = new Date(e.InsertDate);
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          return `${day}/${month}`;
        }),
        datasets: [
          {
            ...this.latestBarChart.data.datasets[0],
            data: productivityValues,

          },
        ],
      };

      this.chart_series = [{ name: globalThis._lang('t_productivity'), data: productivityValues }];
      this.statisticsProfit.chartOptions.xaxis.categories = newData.labels;
      // this.latestBarChart = {
      //   ...this.latestBarChart,
      //   data: newData,
      // };

      this.p_chart_id = new Date().getTime();


    },
    GetRemoteNumber(phone) {
      if (globalThis.v_navbar.other_permission.includes("mask_remote_number")) {
        return globalThis.maskPhoneNumber(phone.split(' ').join('').replace(/\D+/g, ''));
      } else {
        return phone.split(' ').join('').replace(/\D+/g, '');
      }
    },
    toHHMMSS(sec_num) {
      try {
        return new Date(sec_num * 1000).toISOString().substr(11, 8);
      } catch (error) {
        return sec_num;
      }
    },
    set_waiting_in_queue_color(count) {
      if (Number(count) == 0) {
        return 'light-secondary'
      }
      else if (Number(count) == 1) {
        return 'light-primary'
      }
      else if (Number(count) == 2) {
        return 'light-warning'
      }
      else if (Number(count) > 2) {
        return 'light-danger'
      }

    },
    openAudio(item) {
      // console.log(item);

      this.selected_audio = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${item.RecordingFile}`;

      // if (this.$refs.player != undefined) {
      //   this.$refs.player.player.currentSrc = this.selected_audio;
      // }
      this.htmlcontent = `<audio id="voyce_audio" ref="player" controls ${globalThis.v_navbar.other_permission.includes("agent_download_sound")
        ? ""
        : 'controlsList = "nodownload"'
        } preload="none" style = "width:100%">
                <source src="${this.selected_audio}" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>`;
      this.audio_modal = true;
      this.$swal({
        title: `<span class="font-weight-bolder">${item.finish_code}</span><br><span class="font-weight-bolder">  ${item.contact_number}</span>`,
        html: this.htmlcontent,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        onClose: this.close_modal,
      });
    },
    get_ch: async function () {

      var response = (
        await this.$http_in.get(
          `agent/v1/CallHistory/${globalThis.user.username}`
        )
      ).data;

      this.ch_data = [];
      response.forEach(row => {
        this.$set(this.ch_data, this.ch_data.length, {
          insert_date: new Date(row.StartTime + 1000 * 60 * 3 * 60)
            .toISOString()
            .substr(11, 5),
          contact_number:
            row.InternationalCall == 'national'
              ? row.BaseRemoteNumber
              : row.RemoteNumber,
          finish_code: row.Verdict,
          queuename: row.Direction == 'outbound' ? row.OutboundQueue : row.Queue,
          call_direction: row.Direction,
          ConnectedLength: toHHMMSS(Math.round(row.ConnectedLength / 1000)),
          ACWLength: toHHMMSS(Math.round(row.ACWLength / 1000)),
          note: row.FinishCodeDetails ? row.FinishCodeDetails?.note : '',
          RecordingFile: globalThis.v_navbar.other_permission.includes(
            'agent_display_sound'
          )
            ? row.RecordingFile
            : ''
        });
      });
      // console.log("CH", this.ch_data);
    },
    start_call(phone) {
      globalThis.LayoutContentRendererDefault.preview_call_request(phone);
    },
    get_live_agent: async function () {

      var response = (
        await this.$http_in.get(`qmgr/v1/LiveAgent/${globalThis.user.username}`)
      ).data;

      // console.log("get_live_agent", response);

      try {
        let callResponseCounts = {
          totalDialerCalls: (response.AcceptedCalls + response.AcceptedDialerCalls + response.MissedDialerCalls),
          totalCallsWithOutbound: (response.AcceptedCalls + response.AcceptedDialerCalls + response.MissedDialerCalls + response.SuccessfulOutboundCalls),
          totalCallsWithOutboundFailures: (response.AcceptedCalls + response.AcceptedDialerCalls + response.SuccessfulOutboundCalls + response.FailedOutboundcalls)
        };

        this.performance_info.totalCallTime.value = toHHMMSS(response.Productivity.InInteraction);
        this.performance_info.avgCallTime.value = toHHMMSS(Math.round(response.Productivity.InInteraction / safeDivision(callResponseCounts.totalCallsWithOutbound)));
        this.performance_info.avgAnswerTime.value = callResponseCounts.totalDialerCalls === 0 ? '00:00:00' : toHHMMSS(Math.round(response.Productivity.AgentRinging / callResponseCounts.totalDialerCalls));
        this.performance_info.avgHoldTime.value = toHHMMSS(Math.round(response.Productivity.HoldTime / safeDivision(callResponseCounts.totalCallsWithOutbound)));
        this.performance_info.avgAcwTime.value = toHHMMSS(Math.round(response.Productivity.ACW / safeDivision(callResponseCounts.totalCallsWithOutboundFailures)));

        globalThis.user.loggedInAt = response.LoggedInAt;
        // STATS
        this.statistics.productivity = response?.Productivity?.calculated ?? 0;
        this.statistics.inbound_success = response.AcceptedCalls ?? 0;
        this.statistics.inbound_failed = response.MissedCalls ?? 0;
        this.statistics.outbound_success = ((response.SuccessfulOutboundCalls ?? 0) + (response.AcceptedDialerCalls ?? 0));
        this.statistics.outbound_failed = response.FailedOutboundcalls ?? 0;
        this.statistics.inbound_avg_time = toHHMMSS(
          (response.ReasonLengths["In Call - Inbound"] ?? 0) /
          (response.AcceptedCalls ?? 0) +
          (response.MissedCalls ?? 0)
        ) ?? 0;
        this.statistics.outbound_avg_time =
          toHHMMSS(
            (response.ReasonLengths["In Call - Outbound"] ?? 0) /
            (response.SuccessfulOutboundCalls ?? 0) +
            (response.FailedOutboundcalls ?? 0)
          ) ?? 0;
      } catch (error) {
        console.log(error);
      }

      try {
        // FINISH CODES
        this.fc_data = [];
        this.total_finish_code_counts = 0;
        for (const item of Object.keys(response.FinishCodeCounts)) {
          this.total_finish_code_counts += response.FinishCodeCounts[item];
        }

        for (const item of Object.keys(response.FinishCodeCounts)) {
          // console.log("globalThis.finish_codes.find(j => j.display_name == item)",globalThis.finish_codes.find(j => j.finish_code == item) );
          let _fc = globalThis.finish_codes.find((j) => j.finish_code == item);
          if (_fc && !_fc.show_in_dashboard) {
            let _other = this.fc_data.find((e) => e.finish_code == "Other");
            if (_other != undefined) {
              _other.count += response.FinishCodeCounts[item];
              _other.rate += response.FinishCodeCounts[item];
            } else {
              this.fc_data.push({
                finish_code: "Other",
                count: response.FinishCodeCounts[item],
                rate: response.FinishCodeCounts[item],
                category: globalThis._lang('t_undefined'),
              });
            }
          } else {
            if (_fc) {
              this.fc_data.push({
                finish_code: item,
                count: response.FinishCodeCounts[item],
                rate: response.FinishCodeCounts[item],
                category: globalThis.finish_categories.find(
                  (e) => e._id == _fc.category_id
                ).category,
              });
            }
          }
        }
      } catch (error) {
        console.log(error);

      }
      try {
        // STATUS
        this.statistics.break_time = 0;
        this.st_data = [];
        for (const item of Object.keys(response.ReasonLengths)) {
          if (['Offline', ''].includes(item)) {
            continue;
          }
          // console.log("globalThis.finish_codes.find(j => j.display_name == item)",globalThis.finish_codes.find(j => j.finish_code == item) );
          let _st = globalThis.statuses.find((j) => j.display_name == item);
          if (_st &&
            globalThis.status_categories.find(
              (e) => e._id == _st.status_category_id
            ).internal_name == "break"
          ) {
            this.statistics.break_time += response.ReasonLengths[item];
          }
          if (_st && !_st.show_in_dashboard) {
            let _other = this.st_data.find((e) => e.display_name == "Other");
            if (_other != undefined) {
              _other.time += response.ReasonLengths[item];
            } else {
              this.st_data.push({
                display_name: "Other",
                time: response.ReasonLengths[item],
                category: globalThis._lang('t_undefined'),
              });
            }
          } else {
            if (_st) {
              this.st_data.push({
                display_name: item,
                time: response.ReasonLengths[item],
                category: globalThis.status_categories.find(
                  (e) => e._id == _st.status_category_id
                ).display_name,
              });
            }
          }
        }

        console.log("st_data", this.st_data);

        this.doughnutChart.data.datasets[0].labels = this.st_data.map(e => e.display_name);
        this.doughnutChart.data.datasets[0].data = this.st_data.map(e => e.time);
        // this.chartjsData.data.labels = [];
        // this.chartjsData.data.datasets[0].data = [];
        // let cols = [];
        // let vals = [];
        // this.statistics.break_time = 0;
        // for (const item of Object.keys(response.ReasonLengths)) {
        //   if (item == "") {
        //     continue;
        //   }
        //   let _statu = globalThis.statuses.find((e) => e.display_name == item);
        //   if (_statu && _statu.show_in_dashboard) {
        //     if (
        //       globalThis.status_categories.find(
        //         (e) => e._id == _statu.status_category_id
        //       ).internal_name == "break"
        //     ) {
        //       this.statistics.break_time += response.ReasonLengths[item];
        //     }
        //     if (item.includes(' - ')) {
        //       cols.push(item.split(' - ')[1]);
        //     }
        //     else {
        //       cols.push(item);
        //     }
        //     vals.push(response.ReasonLengths[item]);
        //   }
        // }

        // console.log("this.statistics.total_break_time", this.statistics.total_break_time);
        // console.log("this.statistics.break_time", this.statistics.break_time);
        if (this.statistics.total_break_time <= this.statistics.break_time) {
          this.statistics.remaining_break_time = toHHMMSS(0);
          this.statistics.break_time = toHHMMSS(this.statistics.break_time);
        } else {
          this.statistics.remaining_break_time = toHHMMSS(
            this.statistics.total_break_time - this.statistics.break_time
          );
          this.statistics.break_time = toHHMMSS(this.statistics.break_time);
        }

        // this.chartjsData.data = {
        //   ...this.chartjsData.data,
        //   datasets: [
        //     {
        //       data: [...vals],
        //       backgroundColor: "#7367F0",
        //       borderColor: "transparent",
        //     },
        //   ],
        //   labels: [...cols],
        // };

        // this.custom_key = Math.floor(Math.random() * 101);
      } catch (error) {
        console.log(error);

      }

    },
    openNote(note) {
      this.$swal({
        html: note,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
      });
    },
    get_queues: async function () {
      var response = (await this.$http_in.get(`agent/v1/Queue`)).data;
      this.queues = [];
      this.queues.push({
        internal_name: "undefined",
        display_name: "Undefined",
      });

      this.queues = [...this.queues, ...response];
      //
    },
    GetCollectionStatistics: async function (_date) {
      var response = (
        await this.$http_in.get(`agent/v1/CollectionStatistics/${_date}`)
      ).data;

      // console.log("response.bar_info[0]",response.bar_info[0]);
      if (response.bar_info == undefined) {
        return;
      }
      this.collection_statistics = {
        ...response.bar_info,
      };

      let date = new Date(_date);
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      var _bussiness_days = this.businessDays(firstDay, lastDay);
      var bussiness_days = _bussiness_days.length;
      var daily_goals = [];
      var daily_goals_columns = [];
      // console.log("_bussiness_days",_bussiness_days);
      for (let index = 0; index < bussiness_days; index++) {
        // daily_goals_columns.push(parseInt(index) + 1);
        daily_goals.push(
          parseInt(
            ((parseInt(index) + 1) *
              parseInt(this.collection_statistics.collection_goal)) /
            bussiness_days
          )
        );
      }

      var _array = [];
      for (const item of _bussiness_days) {
        this.monthly_chart_info[item] = 0;
        // console.log("item",item);
        for (const item3 of response.monthly_chart_info) {
          // console.log("item3",item3);

          if (new Date(item3.collection_date).getDate() <= parseInt(item)) {
            // console.log("item33",parseInt(item3.collection_payment));

            this.monthly_chart_info[item] += parseInt(item3.collection_payment);
          }
        }
      }
      // console.log("this.monthly_chart_info",this.monthly_chart_info);
      var _tmp = Object.values(this.monthly_chart_info);
      var _tmp = Object.values(this.monthly_chart_info);
      _tmp.sort(function (a, b) {
        return a - b;
      });
      // console.log("daily_goals", daily_goals);
      // console.log("_bussiness_days", _bussiness_days);
      // console.log("this.revenueComparisonLine", this.revenueComparisonLine);

      let _co = this.revenueComparisonLine.chartOptions;
      _co.xaxis.categories = _bussiness_days;

      this.revenueComparisonLine.chartOptions = {
        ..._co,
      };
      // this.revenueComparisonLine.chartOptions.xaxis.categories = _bussiness_days;
      this.revenueComparisonLine.series[0].data = _tmp;
      this.revenueComparisonLine.series[1].data = daily_goals;
    },
    businessDays(date1, date2) {
      var days = ["N", "Y", "Y", "Y", "Y", "Y", "N"];

      var d1 = new Date(date1);
      var d2 = new Date(date2);

      var isGunu = 0;
      var _days = [];
      while (true) {
        if (d1 > d2) {
          break;
        }

        var dayName = days[d1.getDay()];

        if (dayName != "N") {
          isGunu++;
          _days.push(d1.getDate());
        }

        d1.setDate(d1.getDate() + 1);
      }
      return _days;
    },
  },
  computed: {
    dynamicCols() {
      return window.innerWidth <= 1366 ? '12' : '6';
    },
    maskPhoneNumber() {
      return globalThis.maskPhoneNumber; // globalThis üzerinden global değişkene erişim
    }
  },
  mounted: async function () {
    globalThis.statisticts = this;
    this.other_permission = globalThis.v_navbar.other_permission;
    // if (globalThis.projects.length == 0) {
    //   var getproject_interval = setInterval(async ()=>{
    //     if(globalThis.v_navbar && globalThis.v_navbar.GetProjects){
    //       clearInterval(getproject_interval);
    //       await globalThis.v_navbar.GetProjects();
    //     }
    //   }, 500)
    // }

    let _agent_statisticts = localStorage.getItem("agent_statisticts");
    if (![undefined, null].includes(_agent_statisticts)) {
      const this_data = JSON.parse(_agent_statisticts);
      for (let [key, val] of Object.entries(this_data)) {
        this[key] = val;
      }
    } else {
      await this.get_ch();
      await this.getProductivtyIndex();
    }

    globalThis.qm_status_watcher.on("connected", () => {
      this.get_live_agent();
    });
    await this.get_queues();




    let _self = this;

    setInterval(() => {
      _self.waiting_on_queue_data = [];

      for (const item of Object.keys(window.v_navbar.interaction_queues)) {
        _self.waiting_on_queue_data.push({
          queue: item,
          call_count: window.v_navbar.interaction_queues[item].size,
        });
      }
      _self.waiting_on_queue_data = [..._self.waiting_on_queue_data];
    }, 2000);

    this.statistics.total_break_time = globalThis.projects.find(
      (e) => e.internal_name == globalThis.user.selected_project
    ).break_time;
    if (globalThis.user.shift) {
      this.statistics.total_break_time = Number(globalThis.user.shift.break_time * 60);
    }
    if (_voyce.$qm && _voyce.$qm.connected) {
      this.get_live_agent();
    }
  },
  beforeDestroy: function () {
    localStorage.setItem("agent_statisticts", JSON.stringify(this.$data));

    if (this.queue_waiting_count_interval != "") {
      clearInterval(this.queue_waiting_count_interval);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.card-body {
  position: relative;
  .pie-text {
    width: 105px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
}
.large-2 {
  margin-left: 30px;
  float: left;
  height: 300px;
  overflow-y: scroll;
  width: 100px;
  background: #ccc;
}

.force-overflow {
  min-height: 450px;
}

.large-2::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #a8a8a8;
}

.large-2::-webkit-scrollbar {
  width: 10px;
}

.large-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #a8a8a8;
}
</style>
